import React from "react";

const TermosDeUso = () => {
    return (
        <main className="terms-of-use">
            <img className="logo" src={require("../components/assets/img/header/menu/ic-logo.png")} alt="Gifthy"></img>
            <h2 className="page-title font-bold">Termos de Uso e Serviços</h2>
            <h4 className="page-subtitle">Bem-vindo ao Gifthy!</h4>
            <p className="item-paragraph">O Gifhty é uma rede social com objetivo específico de facilitar o ato de presentear. Tem por funcionalidade a integração de pessoas e a troca de presentes entre elas, com base em suas escolhas ou navegabilidade.</p>
            <p className="item-paragraph">Na Rede Gifthy você encontrará produtos expostos em sistema de marketplace, os quais poderá dar de presente aos seus amigos, parentes, colegas, etc. Você também poderá comprar estes produtos para você mesmo.</p>
            <p className="item-paragraph">Não cobramos pelo uso do Gifthy.</p>
            <p className="item-paragraph">Para fornecer os serviços e produtos da Rede Gifthy, precisamos ter acesso e processar informações sobre você. Trabalharemos com informações pessoais e cadastrais e também (fase 2) com informações colhidas por dispositivo e parceiros.</p>
            <p className="item-paragraph">Relativo às informações pessoais e cadastrais, você terá de completar um cadastro, criando um usuário da Rede Gifthy, momento em que precisará informar seu nome, sobrenome, CPF, data de nascimento, apelido, endereço e e-mail. Apelido será como seu perfil aparecerá para você e seus contatos.</p>
            <p className="item-paragraph">Você também deverá criar uma senha, que deve ser pessoal e intransferível.</p>
            <p className="item-paragraph">Para operacionalizar o objetivo da Gifthy de facilitar a compra de presentes você terá de informar seus desejos de presentes, escolhendo para integrar uma lista pessoal os itens que gostaria de receber em suas datas especiais.</p>
            <p className="item-paragraph">Para realizar compras utilizando cartões de débito ou de crédito, no momento de realizar o pagamento você deverá informar os respectivos números aos sistemas de pagamento utilizados pela Gifthy.</p>
            <p className="item-paragraph">Com exceção de sua data de aniversário e sua idade (que poderá ser compartilhada apenas se você permitir), não compartilhamos qualquer informação de identificação pessoal ou cadastro (como nome, endereço de email, endereço físico, dados de cartões ou qualquer outra). Seus dados são de conhecimento apenas de você e da Gifthy Network, titular da Rede Gifthy e, no que tange aos financeiros, das empresas operadoras dos meios de pagamento.</p>
            <p className="item-paragraph">Ao usar o Gifthy, você concorda que podemos mostrar anúncios que consideramos relevantes para você e pelos integrantes de sua rede. Usamos seus dados pessoais, exclusivamente para conhecer suas datas importantes e facilitar suas escolhas de presentes. Isso pode ajudar a determinar quais anúncios mostrar. Não vendemos seus dados pessoais.</p>
            <p className="item-paragraph font-bold underline">Ao se cadastrar como usuário e aceitar eletronicamente o presente Termo de Uso você declara automática e incondicionalmente estar de acordo com este Termo.</p>

             {/* ----- 1 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">1. Os serviços fornecidos</h3>
                <p className="item-paragraph">Seu Assistente para Presentear Gifthy visa auxiliar você no ato de presentear, informando datas importantes de seus contatos na rede e sugerindo o presente ideal para ser comprado para cada um deles. Também permite que você compre um item para você mesmo. O Gifthy oferece os seguintes serviços:</p>
                <p className="list-item">- lembrança da data especial: o Gifthy informará a você da aproximação de uma data especial para um contato, como aniversário, festa de casamento, festa da empresa, etc.;</p>
                <p className="list-item">- escolha de presentes para receber: você poderá escolher, dentre os produtos fornecidos pela Rede Gifthy, quais presentes gostaria de receber em suas datas especiais;</p>
                <p className="list-item">- sugestão do presente ideal: o Gifthy sugerirá a você o presente que seu contato na rede gostaria de receber, usando para tanto as próprias escolhas do “presenteado” ou suas preferências de navegação (fase 2);</p>                
                <p className="list-item">- possibilidade de criação de <span className="font-bold">eventos</span>: no Gifthy você poderá criar o seu próprio evento (p. exemplo: festa de aniversário) e convidar os contatos que quiser. <span className="font-bold">Como funciona</span>: você cadastra o seu evento, vincula a ele os produtos e convida seus amigos para participarem. O convite pode ser enviado por e-mail, WhatsApp ou qualquer outro meio eletrônico através de link que o Gifthy disponibiliza para ser copiado e colado onde você quiser. O evento poderá ser adiado e editado até a data de sua realização. Quando for cancelado ou suspenso, será tratado como finalizado. As compras realizadas relativas a eventos cancelados ou suspensos serão concretizadas normalmente;</p>                            
                <p className="list-item">- possibilidade de criação de <span className="font-bold">vaquinhas</span>: você também poderá criar uma vaquinha para dar ao seu amigo um presente especial, dividindo o valor com outros integrantes de sua rede. Você também pode criar uma vaquinha para você mesmo ou para, por exemplo, uma instituição que você representa. <span className="font-bold">Como funciona</span>: você visualiza a lista de desejos de um amigo e escolhe fazer uma vaquinha de um dos desejos. Caso você queira criar uma vaquinha para você ou para terceiros, você deve ir até a seção “vaquinha” do menu inicial e, a partir de lá, criar a vaquinha. Você deve selecionar todos os participantes da vaquinha, que serão notificados por e-mail e também pelas notificações internas do sistema Gifthy para terem conhecimento da vaquinha e poderem participar. A vaquinha será encerrada na data programada pelo criador. Também poderá ser finalizada antecipadamente, sem prejuízo da destinação dos valores arrecadados. <span className="font-bold">Para participar</span>, você deve selecionar a vaquinha específica e clicar no botão “participar” e aí poderá informar o valor de sua contribuição, que será debitado em seu cartão de crédito ou na sua carteira virtual. Você terá a opção de contribuir anonimamente para uma vaquinha. <span className="font-bold">Atingindo a vaquinha sua meta a compra será realizada e a nota fiscal expedida em nome do beneficiado pela vaquinha. Caso a vaquinha não atinja seu objetivo no tempo programado o amigo beneficiado receberá o crédito correspondente ao valor das arrecadações em sua carteira virtual no Gifthy;</span></p>                            
                <p className="list-item">- compras on line;</p>                            
                <p className="list-item">- convite por e-mail ou outras redes sociais: você poderá convidar seus amigos para fazerem parte da Rede Gifthy através de mensagem de e-mail ou link para ser utilizado em outras redes sócias das quais participa ou qualquer outro meio virtual (SMS, por exemplo);</p>
                <p className="list-item"><span className="font-bold">- carteira virtual:</span> sua carteira virtual é o valor que você possui para usar no Gifthy com a compra de itens para seus amigos ou para você mesmo. O valor constante da carteira virtual pode ter origem em bônus concedidos pelo Gifthy, créditos de vaquinhas, estornos de compras, devoluções, entre outras origens.</p>
                <p className="list-item">As opções de operações de compra, nas telas funcionais do Gifthy são:</p>
                <p className="list-sub-item">- para presentear um amigo: botão presentear;</p>
                <p className="list-sub-item">- para comprar o item para você: botão comprar;</p>
                <p className="list-sub-item">- para criar uma vaquinha: botão vaquinha.</p>
                <p className="item-paragraph">A Gifthy Network Ltda. está constantemente usando e desenvolvendo tecnologias avançadas para fornecer segurança e funcionalidade para todos os seus usuários e parceiros comerciais, independentemente de capacidade física (fase 3) ou localização geográfica. Nossos serviços ajudam você a encontrar e a se conectar com pessoas ou grupos que considera importantes, com o objetivo específico de facilitar o ato de presentear. Usamos as informações com as quais os usuários Gifthy alimentam a plataforma para melhorar ainda mais a experiência da troca de presentes.</p>
            </article>
            
            {/* ----- 2 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">2. Os serviços fornecidos</h3>
                <p className="item-paragraph">A plataforma se remunera de comissões pagas pelas lojas que expõem os produtos no marketplace e também de ações e publicidade promovidas por seus parceiros comerciais.</p>
            </article>

            {/* ----- 3 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">3. Seu compromisso com o Gifthy e com nossa comunidade</h3>                
                <p className="item-paragraph">Fornecemos estes serviços para você e para outras pessoas a fim de ajudar a promover nossa missão. Em troca, precisamos que você assuma os seguintes compromissos:</p>
                <p className="list-item">a) quem pode usar o Gifthy: a plataforma e a Rede Social Gifthy pode ser utilizada por qualquer pessoa, de qualquer idade, independentemente de ser ou não economicamente ativa, para escolher que presentes gostaria de ganhar em suas datas especiais, criar eventos e vaquinhas. Entretanto, para poder comprar os presentes e ofertá-los a amigos, o usuário deverá poder realizar o pagamento dos mesmos, através de cartão de crédito, débito ou boleto bancário, dependendo do meio de pagamento que for utilizar;</p>
                <p className="list-item">b) o que você pode compartilhar e fazer no Gifthy: você poderá compartilhar seu aniversário, escolher presentes dentre os produtos disponíveis, encontrar amigos já cadastrados no Gifthy, convidar amigos não cadastrados para fazerem parte de sua rede através de e-mail ou link para as outras redes sociais, criar eventos e vaquinhas, comprar um item para você mesmo ou usar o Gifthy para comprar um item para pessoa nãocadastrada a quem você deseja presentear pessoalmente;</p>
                <p className="list-item">c) as permissões que você nos concede: você nos permite saber sua data de nascimento e endereço. Sua idade poderá ser divulgada dentro de sua rede somente de você permitir. Seu endereço é de conhecimento apenas de você e da Gifthy. Para realizar compras por cartão de crédito ou débito você deverá informar à Gifthy os dados de seu cartão. A Gifthy utiliza sistemas de pagamento tradicionais e conhecidos no mundo do e-commerce, seguros e largamente utilizados por todos que realizam compras virtuais, de modo que os dados de seu cartão estarão protegidos.</p>
            </article>

            {/* ----- 4----- */}
            <article className="item">
                <h3 className="item-title font-regular">4. Limites no uso de nossa propriedade intelectual</h3>                
                <p className="item-paragraph">O sistema Gifthy tem pedido de concessão de patente e registro de marca mista depositados junto aos respectivos órgão de proteção no Brasil e exterior. Você só pode usar nossos direitos autorais ou marcas em processo de registro se expressamente autorizado e com permissão prévia por escrito.</p>
            </article>

            {/* ----- 5 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">5. Disposições adicionais</h3>
                <p className="item-paragraph">a) atualização de nossos Termos: estes termos valem a partir de 1º de agosto de 2020. Eventuais acréscimos ou atualizações serão publicadas em nossos endereços de Internet para acesso via WEB e APP;</p>
                <p className="item-paragraph">b) suspensão ou encerramento da conta: a Gifthy reserva-se no direito de suspender ou encerrar sua conta na Rede Gifthy se verificar que suas informações e dados pessoais não são verdadeiros ou violarem as boas práticas comerciais, especialmente mas não exclusivamente no que tange a fraudes em compras, pagamentos, uso de cartões clonados ou, ainda, se de alguma forma implicarem o cometimento de qualquer ato ilícito;</p>                
            </article>

            {/* ----- 6 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">6. Limites da responsabilidade</h3>                
                <p className="item-paragraph">A Gifthy é uma rede social de troca de presentes. Seu objetivo é possibilitar que pessoas conectadas entre si possam comprar e receber o presente ideal pelo menor esforço e custo. A Gifthy não vende os produtos expostos em sua plataforma, sendo mera intermediária entre o usuário e as empresas vendedoras. Nessa condição, não é responsável pela natureza dos produtos ou por qualquer defeito que eventualmente possuam, nem tampouco por falhas na logística de entrega, tais como atrasos, nãoentrega, ou qualquer outra que possa frustrar a expectativa dos usuários neste ponto específico.</p>
            </article>

            {/* ----- 7 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">7. Responsabilidade do usuário</h3>                
                <p className="item-paragraph">O usuário é o único responsável pelo fornecimento à Gifthy de suas informações pessoais, devendo zelar pela veracidade das mesmas, de modo que deverá indenizar a Gifthy por todos os danos eventualmente causados pela prestação de informações incorretas, inverídicas ou desatualizadas em seu cadastro ou prestadas durante a utilização do sistema. O usuário também será responsável pelos atos praticados por terceiros em seu nome, mediante a utilização do seu cadastro.</p>
            </article>

            {/* ----- 8 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">8. Contestações</h3>                
                <p className="item-paragraph">Havendo reclamações ou contestações pertinentes ao uso da Rede Gifthy ou aos serviços que presta, devem ser encaminhadas para o e-mail contato@gifthy.com.br.</p>
            </article>

            {/* ----- 9 ----- */}
            <article className="item">
                <h3 className="item-title font-regular">9. Outros termos e políticas que podem se aplicar a você</h3>                
                <p className="list-item">a) Termos e Políticas Comerciais: se você for um parceiro comerciais da Gifthy, além destes, se aplicarão a você os termos constantes no contrato específico de Prestação de Serviços e Hospedagem de Vitrine Virtual;</p>
                <p className="list-item">b) Políticas de Publicidade: especificam quais tipos de conteúdo de anúncio são permitidos por parceiros que anunciam na Rede Gifthy;</p>
                <p className="list-item">c) Termos de Autoatendimento de Publicidade: esses termos são aplicáveis quando você usa as interfaces de autoatendimento de publicidade para criar, enviar ou veicular publicidade ou outra atividade ou conteúdo comercial ou patrocinado;</p>
                <p className="list-item">d) Políticas de Páginas, Grupos e Eventos: essas diretrizes são aplicáveis se você cria ou administra uma Página eventualmente existente, grupo ou evento na Rede Gifthy ou se usa a Gifthy para divulgar ou administrar uma promoção; e) Política da Plataforma do Gifthy: essas diretrizes descrevem as políticas aplicáveis a seu uso de nossa Plataforma (por exemplo, para desenvolvedores ou operadores de um aplicativo ou site da Plataforma ou se você usa plugins sociais);</p>
                <p className="list-item">e) Termos de Pagamento do Desenvolvedor: esses termos se aplicam aos desenvolvedores de aplicativos que usam os Pagamentos do Gifthy;</p>
                <p className="list-item">f) Termos de Pagamento da Comunidade: esses termos se aplicam aos pagamentos feitos no ou por meio do Gifthy;</p>
                <p className="list-item">g) Recursos da Marca do Gifthy: essas diretrizes descrevem as políticas que se aplicam ao uso das marcas comerciais, logotipos e capturas de tela do Gifthy;</p>
                <p className="list-item">h) Diretrizes de Música: essas diretrizes descrevem as políticas que se aplicam caso você publique ou compartilhe conteúdo com música no Gifthy, como por exemplo, um evento específico, uma festa virtual, etc., quando deverá observar as regras de divulgação de obras fonográficas e audiovisuais, pelas quais se responsabiliza exclusivamente, isentando a Gifthy Network de qualquer responsabilidade</p>
            </article>
        </main>
    )
}

export default TermosDeUso;